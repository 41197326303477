import React, { useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { FaHandPaper } from 'react-icons/fa';
import Typical from 'react-typical';
import header from '../assets/img/header-img.png';
import TrackVisibility from 'react-on-screen';
import foggy from '../assets/img/foggy.jpg';
import resume from '../assets/resume/ATIEH AMINI-React developer.docx';
import emoji from '../assets/img/emoji.gif';
import 'animate.css';
const Banner = () => {
  const [modalShow, setModalShow] = React.useState(false);
  function MyVerticallyCenteredModal(props) {
    return (
      <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Nivedika's Poem</Modal.Title>
        </Modal.Header>
        <div className="foggy">
          <Modal.Body>
            <p style={{ boxShadow: '0.2', fontSize: '18px', fontWeight: 'bold', color: 'black' }}>
              Fog
              <br /> Foggy clouds, <br />
              Foggy minds <br />
              Faggy things of different Kinds Winds. <br />
              shall blow
              <br />
              Rain shall fall <br />
              But in the end
              <br />
              It won't matter at all, <br />
              Foggy minds,
              <br /> Foggy clouds, <br />
              Foggy wishes
              <br /> said out loud, <br />
              I'm in a world where I am proud. <br />
              Fogginess might confuse
              <br />
              But look a little closer.
              <br />
              And the fog will clear Your dreams & wishes shall come very near. <br />
              Foggy mind,
              <br />
              Foggy wishes, <br />
              Foggy clouds, <br />
              Foggy dreams of the dishes, <br /> 19 your Poem Mause INSPIRING in a world loud and
              bright, <br />
              I shall shine like a light, <br /> Fogginess begins again,
              <br /> But this time since I'm a light,
              <br /> You are so creative. <br />
              I shall shine bright.
              <br />
              My do AND DRIVE THE FOGGINESS AWAY TO DAlYI!L where <br />
            </p>
          </Modal.Body>
        </div>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  return (
    <section className="banner" id="home">
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div className={isVisible ? 'animate__animated animate__fadeIn' : ''}>
                  <h1>
                    {`Hi I'm `}
                    {''}
                  </h1>
                  <h1>
                    <Typical
                      loop={Infinity}
                      wrapper="span"
                      steps={['Nivedika', 3000, '', 3000, 'Nivedika', 3000]}
                    />
                  </h1>
                  <Button variant="dark" size="lg" onClick={() => setModalShow(true)}>
                    Nivedika's Poem
                    <FaHandPaper size={25} />
                  </Button>{' '}
                </div>
              )}
            </TrackVisibility>
            <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)} />
          </Col>
          <Col xs={12} md={6} xl={5}>
            <img src={header} alt="headder Img" />
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Banner;
